    @keyframes pulse {
        0% {
            transform: scale(0.98);
            /*box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);*/
        }

        70% {
            transform: scale(1);
            /*box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);*/
        }

        100% {
            transform: scale(0.98);
            /*box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);*/
        }
    }

    @keyframes rotate{
        to{ transform: rotate(-360deg); }
    }

    @keyframes colorGreen {
        0% {
            background-color: green;
        }
        50% {
            background-color: #005f00;
        }
        100% {
            background-color: green;
        }
    }

    @keyframes colorRed {
        0% {
            background-color: red;
        }
        50% {
            background-color: #d00000;
        }
        100% {
            background-color: red;
        }
    }